// const aws_exports = {
//   Auth: {
//     Cognito: {
//       region: "us-east-1",
//       userPoolId: "us-east-1_qlrTcQhbQ",
//       userPoolClientId: "t069ve613pe9aaonnuqau13r1",
//       identityPoolId: "us-east-1:ff873897-f6e3-421a-8b72-05cbcadc90a2",
//     },
//   },
//   API: {
//     REST: {
//       "code": {
//         endpoint: "https://de0eslvfga.execute-api.us-east-1.amazonaws.com/PROD/",
//         region: "us-east-1"
//       },
//       "codev2": {
//         endpoint: "https://hjizsqgesl.execute-api.us-east-1.amazonaws.com/PROD/",
//         region: "us-east-1"
//       },
//       "register": {
//         endpoint: "https://hr7oq1zysh.execute-api.us-east-1.amazonaws.com/PROD",
//         region: "us-east-1"
//       }
//     }
//   },
//   Websocket: "wss://zm32lec7b8.execute-api.us-east-1.amazonaws.com/PROD"
// };
// export default aws_exports;

const aws_exports = {
  Auth: {
    Cognito: {
      region: "us-east-1",
      userPoolId: "us-east-1_g996hKXQ9",
      userPoolClientId: "6kugrlebdmrnfsgbn19v7depi2",
      identityPoolId: "us-east-1:81424570-f563-497f-a866-e4f21f2c5517",
    },
  },
  API: {
    REST: {
      "code": {
        endpoint: "https://13glgxr908.execute-api.us-east-1.amazonaws.com/PROD/",
        region: "us-east-1"
      },
      "codev2": {
        endpoint: "https://cmes2pqunc.execute-api.us-east-1.amazonaws.com/PROD/",
        region: "us-east-1"
      },
      "register": {
        endpoint: "https://s5dft9skz2.execute-api.us-east-1.amazonaws.com/PROD/",
        region: "us-east-1"
      }
    }
  },
  Websocket: "wss://bl0jrimfae.execute-api.us-east-1.amazonaws.com/PROD"
};
export default aws_exports;