import React, {useState} from "react";
import { signIn, confirmSignIn } from "aws-amplify/auth";
import { Heading, Input, Button, Text } from "@aws-amplify/ui-react"
import Box from "@mui/material/Box"
import { useNavigate } from "react-router-dom";

const SignInForm = () => {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [step, setStep] = useState("EMAIL"); // Stages: 'EMAIL', 'OTP', 'SUCCESS'
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();
  
    const handleEmailSubmit = async () => {
      try {
        const { nextStep } = await signIn({
          username: email,
          options: {
            authFlowType: "USER_AUTH",
            preferredChallenge: "EMAIL_OTP",
          },
        });

        if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_EMAIL_CODE") {
          setStep("OTP");
        }
      } catch (error) {
        setErrorMessage("Failed to send OTP. Please try again.");
        console.error("Error initiating sign-in:", error);
      }
    };
  
    const handleOtpSubmit = async () => {
      try {
        const { nextStep } = await confirmSignIn({
          challengeResponse: otp,
        });
  
        if (nextStep.signInStep === "DONE") {
          setStep("SUCCESS");
          window.location.reload();
        }
      } catch (error) {
        setErrorMessage("Invalid OTP. Please try again.");
        console.error("Error confirming OTP:", error);
      }
    };
  
    return (
      <>
        <Box
          width="350px"
          margin="auto"
          padding="2rem"
          border="2px solid var(--calpoly-green)" 
          borderRadius="10px"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
          textAlign="center"
          marginBottom="2rem"
          backgroundColor="#f8f8f8"
        >
          {step === "EMAIL" && (
            <Box>
              <Heading
                level={4}
                marginBottom="1rem"
                color="var(--calpoly-green)" 
              >
                Welcome to CCI
              </Heading>
              <Text marginBottom="1rem" fontSize="0.9rem" color="#666">
                Please enter your email to receive a one-time passcode (OTP).
              </Text>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                marginBottom="1rem"
                backgroundColor="#fff"
                border="1px solid var(--calpoly-green)"
                borderRadius="5px"
              />
              <Button
                onClick={handleEmailSubmit}
                variation="primary"
                fullWidth
                style={{
                  backgroundColor: "var(--calpoly-green)",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Send OTP
              </Button>
              {errorMessage && <Text color="red">{errorMessage}</Text>}
            </Box>
          )}
  
          {step === "OTP" && (
            <Box>
              <Heading
                level={4}
                marginBottom="1rem"
                color="var(--calpoly-green)"
              >
                Verify Your Email
              </Heading>
              <Text marginBottom="1rem" fontSize="0.9rem" color="#666">
                Enter the OTP sent to your email to continue.
              </Text>
              <Input
                type="text"
                placeholder="Enter your OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                marginBottom="1rem"
                backgroundColor="#fff"
                border="1px solid var(--calpoly-green)"
                borderRadius="5px"
              />
              <Button
                onClick={handleOtpSubmit}
                variation="primary"
                fullWidth
                style={{
                  backgroundColor: "var(--calpoly-green)",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Verify OTP
              </Button>
              {errorMessage && <Text color="red">{errorMessage}</Text>}
            </Box>
          )}
  
          {step === "SUCCESS" && (
            <Box>
              <Heading
                level={4}
                color="#154734" // Cal Poly Green
                marginBottom="1rem"
              >
                Success!
              </Heading>
              <Text color="#666" marginBottom="1rem">
                You have successfully signed in.
              </Text>
              <Text fontWeight="bold" color="#154734">
                Welcome to the California Cybersecurity Institute.
              </Text>
            </Box>
          )}
        </Box>
      </>
    );
  };

export default SignInForm;